<template>
  <div class="form-header"
    :class="{ 'form-header--small': small, 'form-header--flex': flex }"
  >
    <div v-if="backButtonText"
      class="form-header__back"
      :data-test-id="actionDataTestId"
      @click="$emit('back-button-click')"
    >
      <Icon name="arrow-back-extended" />
      <div>
        {{ backButtonText }}
      </div>
    </div>
    <Headline bold
      size="large"
      class="form-header__headline"
    >
      <div class="form-header__title">
        <slot />
      </div>
      <div v-if="$slots.subtitle"
        class="form-header__subtitle"
      >
        <slot name="subtitle" />
      </div>
    </Headline>
  </div>
</template>

<script>
// Component imports
import Icon from '@/components/partials/Icon.vue'
import Headline from '@/components/partials/Headline.vue'

export default {
  name: 'FormHeader',

  components: { Icon, Headline },

  props: {
    small: { type: Boolean, default: false },
    flex: { type: Boolean, default: false },
    backButtonText: { type: String, default: null },
    actionDataTestId: { type: String, default: null },
  },

  emits: ['back-button-click'],
}
</script>

<style lang="scss" scoped>
.form-header {
  $self: &;

  &__back {
    display: flex;
    gap: 1rem;
    cursor: pointer;
    margin-bottom: 0.75rem;
    color: var(--color-neutral-secondary);
    font-weight: 700;
    font-size: 0.875rem;

    &:deep(span) {
      display: flex;
      align-items: center;
    }

    &:deep(path) {
      fill: var(--color-neutral-secondary);
    }
  }

  &__headline {
    margin-bottom: 1rem;
  }

  &__subtitle {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 0.5rem;
  }

  &--small {
    #{$self}__title {
      font-size: 1.125rem;
    }
  }

  &--flex {
    #{$self}__title {
      display: flex;
      gap: 1rem;
      align-items: center;
    }
  }
}
</style>
