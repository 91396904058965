<template>
  <div
    ref="col"
    class="column"
    :class="{
      'col-xs-12': noXsClass,
      'column--large-pb': largePaddingBottom,
    }"
  >
    <slot />
  </div>
</template>

<script setup>
import { useAttrs, computed } from 'vue';

defineProps({
  buttons: Boolean,
  largePaddingBottom: Boolean,
})

const attrs = useAttrs()

const noXsClass = computed(() => !attrs.class?.includes('col-xs-'))
</script>

<style lang="scss" scoped>
.column {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-bottom: 1rem;
  
  &--large-pb {
    margin-bottom: 1.5rem;
  }

  &--buttons {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
</style>
