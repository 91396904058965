<template>
  <form
    class="form"
    :class="{
      'form--compact': compact,
      'form--very-compact': veryCompact,
    }"
    @submit="e => e.preventDefault()"
  >
    <slot />
  </form>
</template>

<script>
export default {
  name: 'Form',

  props: {
    compact: { type: Boolean, default: false },
    veryCompact: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss" scoped>
.form {
  $self: &;

  // add a margin to all but the first FormHeader components
  &:deep(.form-header ~ .form-header) {
    margin-top: 1rem;
  }

  // decrease the margin bottom on Column components
  &--compact {
    #{$self}:deep(.column) {
      margin-bottom: 1.25rem;
    }
  }

  // decrease the margin bottom on Column components
  &--very-compact {
    #{$self}:deep(.column) {
      margin-bottom: 1rem;
    }
  }
}
</style>
